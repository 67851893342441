import React from "react";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrapper,
  // FooterLinksContainer,
  // FooterLinksWrapper,
  // FooterLinkItems,
  // FooterLinkTitle,
  // FooterLink,
  SocialMedia,
  SocialMediaWrap,
  // SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  SocialMediaElementWrap,
} from "./FooterElements";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterWrapper>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialMediaElementWrap>
                <WebsiteRights>
                  M: 07888724381 | E: enquiries@mcelectricalinstallations.com
                </WebsiteRights>
              </SocialMediaElementWrap>
              <SocialMediaElementWrap>
                <SocialIcons>
                  <SocialIconLink
                    href="https://wa.me/+447888724381"
                    target="-blank"
                    aria-label="Whatsapp"
                  >
                    <FaWhatsapp />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://www.facebook.com/people/MC-Electrical-Installations/100063690155096/"
                    target="-blank"
                    aria-label="Facebook"
                  >
                    <FaFacebook />
                  </SocialIconLink>
                </SocialIcons>
              </SocialMediaElementWrap>
              <SocialMediaElementWrap>
                <WebsiteRights>
                  MC Electrical Installations Ltd © {new Date().getFullYear()}.
                  All rights reserved.
                </WebsiteRights>
              </SocialMediaElementWrap>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrapper>
      </FooterContainer>
    </>
  );
};

export default Footer;

/*
<FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>About us</FooterLinkTitle>
                <FooterLink to="/contactus">How it works</FooterLink>
                <FooterLink to="/contactus">Testimonials</FooterLink>
                <FooterLink to="/contactus">Careers</FooterLink>
                <FooterLink to="/contactus">Investors</FooterLink>
                <FooterLink to="/contactus">Terms of Service</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Contact us</FooterLinkTitle>
                <FooterLink to="/contactus">Contact</FooterLink>
                <FooterLink to="/contactus">Support</FooterLink>
                <FooterLink to="/contactus">Careers</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Social Media</FooterLinkTitle>
                <FooterLink to="/contactus">Facebook</FooterLink>
                <FooterLink to="/contactus">Instagram</FooterLink>
                <FooterLink to="/contactus">LinkedIn</FooterLink>
                <FooterLink to="/contactus">Twitter</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>About us</FooterLinkTitle>
                <FooterLink to="/contactus">How it works</FooterLink>
                <FooterLink to="/contactus">Testimonials</FooterLink>
                <FooterLink to="/contactus">Careers</FooterLink>
                <FooterLink to="/contactus">Investors</FooterLink>
                <FooterLink to="/contactus">Terms of Service</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          */
