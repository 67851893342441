import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  // SideBtnWrap,
  // SidebarRoute,
  StyledLogo,
  LogoContainer,
} from "./SidebarElements";
import Logo from "../../images/nav-logo-white.svg";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <LogoContainer>
          <StyledLogo src={Logo} alt="logo" />
        </LogoContainer>
        <SidebarMenu>
          <SidebarLink
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            // exact="true"
            offset={-40}
            onClick={toggle}
          >
            About
          </SidebarLink>
          <SidebarLink
            to="location"
            smooth={true}
            duration={500}
            spy={true}
            // exact="true"
            offset={0}
            onClick={toggle}
          >
            Location
          </SidebarLink>
          <SidebarLink
            to="services"
            smooth={true}
            duration={500}
            spy={true}
            // exact="true"
            offset={-40}
            onClick={toggle}
          >
            Services
          </SidebarLink>
          <SidebarLink
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
            // exact="true"
            offset={0}
            onClick={toggle}
          >
            Contact
          </SidebarLink>
        </SidebarMenu>
        {/* <SideBtnWrap>
          {isOpen && <SidebarRoute to="/contactus">Contact Us</SidebarRoute>}
        </SideBtnWrap> */}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
