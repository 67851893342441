export const homeObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "MC Electrical Installations",
  headLine: "About Us",
  description:
    "Founded by Martin Clarke over 20 years ago, MC Electrical Installations has since been powering up countless residential, commercial and industrial properties. From full property wiring to small inspection testing, solar panel installation and much more!",
  buttonLabel: "Find out more",
  imgStart: false,
  img: require("../../images/svg-5.svg"),
  alt: "contact-page-link",
  dark: false,
  primary: false,
  darkText: true,
};
export const homeObjTwo = {
  id: "location",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "West Midlands, UK",
  headLine: "Where are MC Electrical Installations based?",
  description:
    "MC Electrical Installations calls the West Midlands home, predominantly lighting up the homes of the Wolverhampton area. However, we continue to connect all those in need from all over the UK!",
  buttonLabel: "Enquire about your area",
  imgStart: true,
  img: require("../../images/svg-3.svg"),
  alt: "Alt text 2",
  dark: true,
  primary: true,
  darkText: false,
};
export const homeObjThree = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Let's talk",
  headLine: "Contact us",
  description:
    "Call, email or message to book your electrical service or ask any questions.",
  buttonLabel: "Whatsapp message",
  imgStart: false,
  img: require("../../images/svg-4.svg"),
  alt: "Alt text",
  dark: false,
  primary: false,
  darkText: true,
};
