import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
// import { MdOutlineElectricalServices } from "react-icons/md";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../../images/nav-logo-white.svg";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  // NavBtn,
  // NavBtnLink,
  StyledLogo,
  RightSideNav,
  LeftSideNav,
} from "./NavbarElements";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <LeftSideNav>
              <NavLogo to="/" onClick={toggleHome}>
                <StyledLogo src={Logo} alt="logo" />
              </NavLogo>
            </LeftSideNav>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <RightSideNav>
              <NavMenu>
                <NavItem>
                  <NavLinks
                    to="about"
                    smooth={true}
                    duration={500}
                    spy={true}
                    // exact="true"
                    offset={0}
                  >
                    About
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to="location"
                    spy={true}
                    smooth={true}
                    duration={500}
                    // exact="true"
                    offset={0}
                  >
                    Location
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to="services"
                    smooth={true}
                    duration={500}
                    spy={true}
                    // exact="true"
                    offset={0}
                  >
                    Services
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to="contact"
                    smooth={true}
                    duration={500}
                    spy={true}
                    // exact="true"
                    offset={0}
                  >
                    Contact
                  </NavLinks>
                </NavItem>
              </NavMenu>
              {/* <NavBtn>
                <NavBtnLink to="/contactus">Contact Us</NavBtnLink>
              </NavBtn> */}
            </RightSideNav>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
