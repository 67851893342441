import React from "react";
import { Button } from "../ButtonElement";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";

import styled from "styled-components";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  ImgWrap,
  TextWrapper,
  TopLine,
  Heading,
  SubTitle,
  BtnWrap,
  Img,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headLine,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  to,
  whatsappLink,
}) => {
  const SocialIconContainer = styled.div`
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    align-items: centre;
    gap: 16px;
  `;

  const SocialIconLink = styled.a`
    color: #25d366;
    font-size: 32px;
    padding-top: 24px;
    text-decoration: none;
    display: flex;
    align-items: centre;
    gap: 16px;
    font-family: "Oswald", sans-serif;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #000;
    }

    @media screen and (max-width: 480px) {
      font-size: 24px;
    }
  `;

  const SocialIconText = styled.p`
    font-size: 20px;
    padding-right: 16px;
    font-family: "Oswald", sans-serif;
    text-decoration: none;
    display: inline;

    &:hover {
    }

    @media screen and (max-width: 480px) {
      font-size: 18px;
    }
  `;
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headLine}</Heading>
                <SubTitle
                  darkText={darkText}
                  style={{ whiteSpace: "pre-line" }}
                >
                  {description}
                </SubTitle>
                {whatsappLink ? (
                  <>
                    <SocialIconContainer>
                      <SocialIconLink
                        href="https://wa.me/+447888724381"
                        target="-blank"
                        aria-label="Whatsapp"
                      >
                        <FaWhatsapp size={32} />

                        <SocialIconText>
                          Message directly via Whatsapp
                        </SocialIconText>
                      </SocialIconLink>
                    </SocialIconContainer>
                    <div
                      style={{
                        display: "flex",
                        gap: "16px",
                        marginBottom: "16px",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <MdOutlinePhone size={32} />
                      <p style={{ fontSize: "20px", color: "#000" }}>
                        07888724381
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "16px",
                        marginBottom: "16px",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ minWidth: 32 }}>
                        <MdOutlineEmail size={32} />
                      </div>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#000",
                          wordBreak: "break-all",
                        }}
                      >
                        enquiries@mcelectricalinstallations.com
                      </p>
                    </div>
                  </>
                ) : (
                  <BtnWrap>
                    <Button
                      to="contact"
                      smooth="true"
                      duration={500}
                      // exact="true"
                      offset={0}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                      dark2={dark2 ? 1 : 0}
                    >
                      {buttonLabel}
                    </Button>
                  </BtnWrap>
                )}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img.default} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
